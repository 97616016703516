import { Link } from 'src/components/Link'
import React from 'react'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import Icon from 'src/icons'
import { getContext } from 'src/components/MyContext'

type IAmInterested = {
    linkText: string
    text?: string
}

const IAmInterested = ({ linkText, text }: IAmInterested) => {
    const ctx = getContext()
    const contactPath = ctx.nav.linkTo('contact')

    return (
        <div
            css={css`
                width: 100%;
                display: flex;
            `}
        >
            <Link
                to={contactPath}
                aria-label={'contact'}
                css={(th: Theme) => css`
                    display: inline-block;
                    position: relative;
                    overflow: hidden;
                    color: ${th.colors.white};
                    &:hover {
                        color: ${th.colors.white};
                    }
                    background: ${th.colors.primary};
                    width: 100%;
                    text-align: center;
                    &:hover {
                        background: ${th.colors.darkGray};
                    }
                    padding: ${th.spacing.md} 0;
                `}
            >
                <div
                    css={(th: Theme) => css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: ${th.fontSize.lg};
                    `}
                >
                    {text ? <span>{text} &nbsp;</span> : ''}
                    <span
                        css={(th: Theme) => css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: ${th.fontWeight.bold};
                            border-bottom: 2px solid ${th.colors.white};
                        }
                        
                    `}
                    >
                        {linkText}
                    </span>
                    <Icon.CaretRight />
                </div>
            </Link>
        </div>
    )
}

export default IAmInterested
