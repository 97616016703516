import { useSpring } from 'react-spring'
import { CSSProperties } from 'react'

const useHoverSpring = ({
    hovering,
    duration,
    values
}: {
    hovering: boolean
    duration?: number
    values: {
        from: CSSProperties
        to: CSSProperties
    }
}) =>
    useSpring({
        to: hovering ? values.to : values.from,
        from: hovering ? values.from : values.to,
        config: {
            duration
        }
    })

export default useHoverSpring
